import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
    Accueil: "/",
    // Blog: "/blog",
    Imprint: "fr/imprint"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/imprint"
);

  const Imprint = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Impression"
          description="Informations sur l'opérateur du site Web, ainsi que les mentions légales."
          lang="fr"
          alternateLangs={alternateLangs}
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <MainContent article={false}>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
  
          <H as="h1">Impression</H>

          <p>Opérateur du site web bluerivermountains.com :</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 Copenhague
          <br />
          Danemark
        </p>
        <p className="baseline">
          Email : <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Téléphone : +45 93 86 85 64
        </p>
        <p className="baseline">Numéro d'identification fiscale : 39474263</p>
        <p>Ce site est protégé par le droit d'auteur de Matthias Kupperschmidt.</p>
        <H as="h2">Légal</H>
        <p>
          Je vérifie régulièrement les informations sur ce site web. Malgré tout le soin apporté, les données peuvent avoir changé entre-temps. Une responsabilité ou garantie pour l'actualité, l'exactitude et l'exhaustivité des informations ne peut donc pas être acceptée. Il en va de même pour les sites web auxquels il est fait référence via des hyperliens. Matthias Kupperschmidt n'est pas responsable du contenu des sites web atteints par le biais d'une telle connexion. La reproduction d'informations ou de données – en particulier l'utilisation de textes, de parties de textes ou d'images – nécessite le consentement préalable. Cela s'applique également à tous les articles de blog. Je signale que je stocke toutes les données saisies dans le masque de recherche et que je n'utilise pas ces données à des fins publicitaires personnelles, ni ne les transmets à des tiers.
        </p>
        <p>
          Pour plus d'informations, veuillez consulter ma <Link to="/fr/politique-de-confidentialité">politique de confidentialité</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;


  